import React from "react"
import { Link } from "gatsby"
import Layout from '../../components/layout'
import { StaticImage } from "gatsby-plugin-image"




const IncusPassesPDR = ({ data }) => {
    return (
        <Layout pageTitle="INCUS | INCUS Passes Preliminary Design Review">
            <h1>INCUS Passes Preliminary Design Review</h1>
            <StaticImage
                alt="A subset of the INCUS Team at Preliminary Design Review"
                src="../../images/incus_pdr_team_2023.jpg"
                layout="constrained"
                placeholder="blurred"
                objectPosition="0 0"
                aspectRatio={3 / 2}
                height={500}
                objectFit="contain"
                transformOptions={{ fit: "cover" }}
            />
            <h5>Sept. 21, 2023</h5>

            <p>PASADENA, CA- The INvestigation of Convective UpdraftS (INCUS), a NASA Earth Ventures Mission led by Colorado State University (CSU) and managed by NASA's Jet Propulsion Laboratory, has passed its Preliminary Design Review (PDR). Passing PDR is a key step for the mission in progressing past <Link to="../timeline">Phase B</Link>, the preliminary design and technology completion phase, and into <Link to="../timeline">Phase C</Link>, the first mission implementation stage, where the hardware will be built, integrated and tested.
            </p>
            <p>Susan van den Heever, Principal Investigator of the INCUS mission said about passing PDR, "the success of such missions depends on inspiration, dedication, hard work, novel solutions and strong teamwork, all of which the INCUS team fully embodies. I am exceptionally proud of our entire INCUS team and am confident that we will be successful in taking our next steps." </p>


        </Layout >

    )
}

export default IncusPassesPDR

