import * as React from 'react'
import useNavigation from "../hooks/use-navigation"
import useSiteMetadata from "../hooks/use-site-metadata"
import Header from "./header"
import Footer from "./footer"
import Container from 'react-bootstrap/Container'

import "../styles.scss"

type LayoutProps = { children: React.ReactNode; className?: string }


const Layout = ({ pageTitle, children }) => {

  const meta = useSiteMetadata()
  const nav = useNavigation()
  return (
    <React.Fragment>
    <Header meta={meta} navTransparent={false} />
    <main>
      <Container>
        {children}
      </Container>
    </main>
    <Footer></Footer>
    </React.Fragment>

  )
}
export default Layout
